// Footer.tsx
import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import { styled } from '@mui/system';

const StyledFooter = styled('footer')(({ theme }) => ({
    padding: theme.spacing(3, 0),
    borderTop: '1px solid rgba(255, 255, 255, 0.2)',
}));

const Footer: React.FC = () => {
    return (
        <StyledFooter>
            <Container>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    flexWrap="wrap" // Garante que o conteúdo fique na mesma linha em telas maiores e permita quebra em telas menores
                >
                    <Typography variant="body2" component="span">
                        ©2024 Aia Context
                    </Typography>
                    <Typography variant="body2" component="span">
                        Feito com muito ❤️
                    </Typography>
                </Box>
            </Container>
        </StyledFooter>
    );
};

export default Footer;
