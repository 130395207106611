// Header.tsx
import React, { useState } from 'react';
import {
  IconButton, Drawer, Box, Typography, Link, List, ListItem, Switch, Button, ButtonGroup, styled, Container,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import imgSrc from './assets/aiacontext_logo.svg';

// Definição das propriedades do componente Header
interface HeaderProps {
  toggleDarkMode: () => void;
  darkMode: boolean;
}

// Estilos personalizados para o cabeçalho
const StyledHeader = styled('header')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: 'rgba(23, 31, 47, 0.9)',
  backdropFilter: 'blur(10px)',
  position: 'fixed',
  width: 'calc(100% - 32px)', // Reduz a largura para adicionar margem lateral
  margin: '16px', // Margem superior e lateral para dar sensação flutuante
  borderRadius: '20px', // Arredondamento dos cantos
  border: '1px solid rgba(255, 255, 255, 0.2)', // Contorno sutil para efeito vidro
  zIndex: 1000,
  padding: '0 16px', // Padding interno para não colar o conteúdo nas bordas
  boxSizing: 'border-box', // Garante que o padding e a borda estejam incluídos na largura total
}));


// Estilos personalizados para a lista de menu
const MenuList = styled(List)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  padding: theme.spacing(1),
}));

// Estilos personalizados para o switch do modo escuro/claro
const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
            '#fff',
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
    width: 32,
    height: 32,
    '&::before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          '#fff',
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
    borderRadius: 20 / 2,
  },
}));


// Componente Header funcional
const Header: React.FC<HeaderProps> = ({ toggleDarkMode, darkMode }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  return (
      <StyledHeader>
        <Container maxWidth="lg"> {/* Adicionando Container com maxWidth para definir o espaço interno */}
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', padding: '20px 0', borderRadius: theme => theme.shape.borderRadius }}>
            <Button href="#home" sx={{ p: 0, minWidth: 'auto' }}>
              <img src={imgSrc} alt="AIAContext Logo" style={{ height: '40px', width: 'auto' }} />
            </Button>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Box sx={{ display: { xs: 'none', sm: 'flex' }, gap: 2 }}>
                <ButtonGroup variant="text" aria-label="outlined primary button group">
                  <Button sx={{ color: 'white' }} href="#home">Home</Button>
                  <Button sx={{ color: 'white' }} href="#sobre">Sobre</Button>
                  <Button sx={{ color: 'white' }} href="#produtos">Produtos</Button>
                  <Button sx={{ color: 'white' }} href="#equipe">Equipe</Button>
                  <Button sx={{ color: 'white' }} href="#contato">Contato</Button>
                </ButtonGroup>
              </Box>
              <MaterialUISwitch
                  checked={darkMode}
                  onChange={toggleDarkMode}
                  inputProps={{ 'aria-label': 'toggle dark mode' }}
              />
              <IconButton
                  onClick={toggleDrawer(true)}
                  size="large"
                  sx={{
                    display: { xs: 'block', sm: 'none' },
                    color: 'white', // Garante que o ícone permaneça branco em ambos os temas
                  }}>
                <MenuIcon />
              </IconButton>
            </Box>
          </Box>
        </Container>
        {/* Drawer para navegação em telas menores */}
        <Drawer
            anchor="right"
            open={drawerOpen}
            onClose={toggleDrawer(false)}
            PaperProps={{
              style: {
                backgroundColor: 'rgba(23, 31, 47, 0.8)', // Fundo translúcido para ambos os temas
                width: 'auto',
                maxHeight: '100vh',
                backdropFilter: 'blur(10px)',
                color: 'white', // Garante que o texto dentro do Drawer permaneça branco
              }
            }}>
          <Box
              role="presentation"
              onClick={toggleDrawer(false)}
              onKeyDown={toggleDrawer(false)}
              sx={{ padding: 2, backgroundColor: 'transparent' }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', position: 'fixed', right: 0, top: 0, zIndex: 5 }}>
              <IconButton onClick={toggleDrawer(false)} sx={{ color: 'white' }}>
                <CloseIcon />
              </IconButton>
            </Box>
            {/* Links de navegação no Drawer */}
            <MenuList>
              <ListItem button component="a" href="#home">Home</ListItem>
              <ListItem button component="a" href="#sobre">Sobre</ListItem>
              <ListItem button component="a" href="#produtos">Produtos</ListItem>
              <ListItem button component="a" href="#equipe">Equipe</ListItem>
              <ListItem button component="a" href="#contato">Contato</ListItem>
            </MenuList>
          </Box>
        </Drawer>
      </StyledHeader>
  );
};

export default Header;
