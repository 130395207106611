// App.tsx
import React, { Suspense, useState } from 'react';
import { createTheme, ThemeProvider, CssBaseline } from '@mui/material';
import Header from './Header';
import About from './About';
import Footer from './Footer';
import ProdutosPage from './Produtos';
import Equipe from './Equipe';
import Contatos from './Contatos';
import Animacao from "./Animacao";
const WelcomeSection = React.lazy(() => import('./Home'));

const App: React.FC = () => {
    const [darkMode, setDarkMode] = useState(() => {
        const preferDarkMode = localStorage.getItem('darkMode');
        return preferDarkMode ? JSON.parse(preferDarkMode) : false;
    });

    const toggleDarkMode = () => {
        const newDarkMode = !darkMode;
        setDarkMode(newDarkMode);
        localStorage.setItem('darkMode', JSON.stringify(newDarkMode));
    };

    // Ajuste do tema para incluir a fonte Noto Sans
    const theme = createTheme({
        palette: {
            mode: darkMode ? 'dark' : 'light',
        },
        typography: {
            fontFamily: "'Noto Sans', sans-serif",
            fontWeightLight: 400, // Noto Sans Light
            fontWeightRegular: 500, // Noto Sans Regular
            fontWeightMedium: 700, // Noto Sans Bold
        },
    });

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline /> {/* Aplica um reset CSS global e normaliza os estilos */}
            <div style={{minHeight: '100vh', display: 'flex', flexDirection: 'column'}}>
                <Header toggleDarkMode={toggleDarkMode} darkMode={darkMode}/>
                <main style={{flex: 1}}>
                    <Suspense fallback={<div>Carregando...</div>}>
                        <section id="home">
                            <WelcomeSection/>
                        </section>
                        <section id="sobre" style={{paddingTop: '70px', marginTop: '-64px'}}>
                            <About/>
                        </section>
                        <section id="produtos" style={{paddingTop: '100px', marginTop: '-64px'}}>
                            <ProdutosPage/>
                        </section>
                        <section id="equipe" style={{paddingTop: '100px', marginTop: '-64px'}}>
                            <Equipe/>
                        </section>
                        <Animacao />
                        <section id="contato" style={{paddingTop: '64px', marginTop: '-64px'}}>
                            <Contatos/>
                        </section>
                        {/* Adicione Animacao conforme necessário */}
                    </Suspense>
                </main>
                <Footer/>
            </div>
        </ThemeProvider>
    );
};

export default App;
