// Equipe.tsx
import React from 'react';
import { Container, Typography, Box, Grid, useTheme, useMediaQuery } from '@mui/material';
import equipeImg from './assets/equipe.png'; // Caminho para a imagem da equipe
import outraImg from './assets/conduta.png'; // Caminho para a segunda imagem

// Isolando entradas de texto
const equipeTextos = {
    titulo: "Nossa Equipe",
    subTitulo: "Sobre Nós",
    descricao: "Somos uma equipe dedicada à inovação e excelência. Com uma paixão compartilhada por criar soluções impactantes, nos esforçamos para superar os desafios e entregar resultados excepcionais.",
    descricaoSegunda: "Nossa missão é transformar ideias em soluções reais que gerem valor para as pessoas e para os negócios. Trabalhamos com dedicação para criar um futuro melhor através da tecnologia e inovação.",
};

const Equipe = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Container maxWidth="lg" sx={{ my: 4 }}>
            <Typography variant="h4" gutterBottom sx={{ textAlign: 'center', marginBottom: 3, color: 'text.primary' }}>
                {equipeTextos.titulo}
            </Typography>
            <Grid container spacing={4} alignItems="center" justifyContent="center">
                {/* Primeiro conjunto de imagem e texto */}
                <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: isSmallScreen ? 'center' : 'flex-start' }}>
                    <Box
                        component="img"
                        src={equipeImg}
                        sx={{
                            height: isSmallScreen ? '300px' : '450px',
                            width: '100%',
                            borderRadius: '20px',
                            objectFit: 'cover',
                        }}
                        alt="Imagem da Equipe"
                    />
                </Grid>
                <Grid item xs={12} md={6} sx={{ textAlign: isSmallScreen ? 'center' : 'left', marginTop: isSmallScreen ? 2 : 0 }}>
                    <Typography variant="h5" sx={{ marginBottom: 2, color: 'text.primary' }}>
                        {equipeTextos.subTitulo}
                    </Typography>
                    <Typography variant="body1" sx={{ color: 'text.primary' }}>
                        {equipeTextos.descricao}
                    </Typography>
                </Grid>
                {/* Segundo conjunto de texto e imagem */}
                <Grid item xs={12} md={6} sx={{ textAlign: isSmallScreen ? 'center' : 'right', marginTop: isSmallScreen ? 2 : 0, order: isSmallScreen ? 2 : 1 }}>
                    <Typography variant="h5" sx={{ marginBottom: 2, color: 'text.primary' }}>
                        Sobre Nossa Missão
                    </Typography>
                    <Typography variant="body1" sx={{ color: 'text.primary' }}>
                        {equipeTextos.descricaoSegunda}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: isSmallScreen ? 'center' : 'flex-end', order: isSmallScreen ? 1 : 2 }}>
                    <Box
                        component="img"
                        src={outraImg}
                        sx={{
                            height: isSmallScreen ? '300px' : '450px',
                            width: '100%',
                            borderRadius: '20px',
                            objectFit: 'cover',
                        }}
                        alt="Outra Imagem"
                    />
                </Grid>
            </Grid>
        </Container>
    );
};

export default Equipe;