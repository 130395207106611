import React from 'react';
import { Box, Container, useTheme, useMediaQuery } from '@mui/material';
import { motion } from 'framer-motion';

interface AnimatedShapeProps {
    shape: 'circle' | 'square' | 'triangle';
    duration: number;
    size: number;
}

const AnimationComponent: React.FC = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    const shapes: AnimatedShapeProps[] = [
        { shape: 'circle', duration: 20, size: 140 },
        { shape: 'square', duration: 7, size: 120 },
        { shape: 'triangle', duration: 4, size: 140 },
    ];

    const getStyle = (shape: 'circle' | 'square' | 'triangle', size: number) => {
        let borderRadius: string;
        let clipPath: string | undefined;

        switch (shape) {
            case 'circle':
                borderRadius = '50%';
                clipPath = undefined;
                break;
            case 'square':
                borderRadius = '20%'; // Cantos arredondados mas não um círculo
                clipPath = undefined;
                break;
            case 'triangle':
                borderRadius = '';
                clipPath = 'polygon(50% 0%, 0% 100%, 100% 100%)';
                break;
        }

        return {
            backdropFilter: 'blur(10px)',
            WebkitBackdropFilter: 'blur(10px)',
            border: '1px solid rgba(255, 255, 255, 0.4)',
            boxShadow: '0 4px 30px rgba(0, 0, 0, 0.4)',
            borderRadius: borderRadius,
            background: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
            clipPath: clipPath,
            width: size,
            height: size,
        };
    };

    return (
        <Container maxWidth="lg" sx={{ position: 'relative', height: 200, overflow: 'hidden', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {shapes.map(({ shape, duration, size }) => (
                <motion.div
                    key={shape}
                    initial={{ x: isSmallScreen ? '0vw' : '0vw' }} // Mesmo valor inicial para simplificar
                    animate={{ x: isSmallScreen ? ['100%', '-100%'] : ['10vw', '-10vw'] }} // Animação específica para telas maiores e menores
                    transition={{
                        repeat: Infinity,
                        duration: duration,
                        ease: "linear",
                        repeatType: 'reverse',
                    }}
                    style={{
                        ...getStyle(shape, size),
                        position: 'absolute',
                        top: '50%',
                        transform: 'translate(-50%, -50%)', // Centralização
                    }}
                />
            ))}
        </Container>
    );
};

export default AnimationComponent;
