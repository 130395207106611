// About.tsx
import React from 'react';
import { Container, Typography, Box } from '@mui/material';

const About = () => {
    return (
        <Container maxWidth="lg" sx={{ my: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <Box sx={{
                width: '100%', // Utiliza 100% da largura do Container
                minHeight: '30vh', // Garante que a seção tenha altura mínima da viewport
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '10px', // Padding para espaçamento interno
            }}>
                {/* Título, Subtítulo e Texto agora usam a cor do texto do tema atual */}
                {/*<Typography variant="h4" gutterBottom sx={{ marginBottom: 2, color: 'text.primary' }}>
                    SEÇÃO 1
                </Typography>*/}

                <Typography variant="h4" gutterBottom sx={{ marginBottom: 1, color: 'text.primary', textAlign: 'center' }}>
                    Somos artesãos de soluções inteligentes
                </Typography>

                <Typography variant="body1" gutterBottom sx={{ textAlign: 'center', color: 'text.primary' }}>
                    Pois o nosso propósito é tecer a inovação nas tramas do cotidiano, facilitando o acesso à educação de qualidade,
                    valorizando a biodiversidade de nossos ecossistemas e promovendo uma sociedade mais inclusiva. Juntos,
                    estamos construindo um futuro onde a tecnologia é uma ponte para oportunidades, um escudo para o nosso
                    planeta e um guia para todos.
                </Typography>
            </Box>
        </Container>
    );
};

export default About;
