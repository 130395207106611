// Produtos.tsx
import React, { useState } from 'react';
import { Box, Container, Typography, Tabs, Tab, Paper, useTheme, styled } from '@mui/material';

// Importações dos SVGs para alternância entre temas claros e escuros
import Logo1Light from './assets/logo_aiaeduca_pos.svg';
import Logo2Light from './assets/logo_gongo_pos.svg';
import Logo3Light from './assets/logo_yris_pos.svg';
import Logo1Dark from './assets/logo_aiaeduca_neg.svg';
import Logo2Dark from './assets/logo_gongo_neg.svg';
import Logo3Dark from './assets/logo_yris_neg.svg';

// Alturas específicas para cada logo para manter a proporcionalidade visual
const logoHeights = [35, 28, 43];

// Descrições detalhadas para cada produto, correspondendo a cada aba
const descriptions = [
    "Inovação no combate à evasão acadêmica, especialmente projetado para cursos de engenharia e ciências sociais aplicadas no ensino superior. " +
    "Este jogo sério, alimentado por inteligência artificial, é desenvolvido com o propósito de engajar estudantes em um ambiente de aprendizado imersivo e interativo. " +
    "Através de simulações realistas e desafios customizados, o Aia Educa visa fortalecer a compreensão de conceitos complexos e fomentar habilidades críticas, " +
    "estimulando o interesse e a persistência dos alunos em suas jornadas acadêmicas. ",

    "Sistema de IA geoespacial, projetado especificamente para otimizar a gestão e conservação das florestas de babaçu na Amazônia. " +
    "Ao integrar análises detalhadas de distribuição de palmeiras, mudanças de uso do solo e dados socioeconômicos, Gongo capacita stakeholders, " +
    "desde comunidades locais a empresas e gestores ambientais, com insights precisos para a tomada de decisões sustentáveis. Este sistema representa " +
    "uma ponte crítica entre tecnologia de ponta e práticas tradicionais, promovendo a exploração econômica responsável e a preservação ambiental.",

    "Tecnologia assistiva inovadora, projetada para transformar a maneira como pessoas com deficiência visual interagem e se orientam em ambientes urbanos. " +
    "Utilizando avançados algoritmos de inteligência artificial e integração com dispositivos móveis, o Yris oferece uma solução de navegação precisa e intuitiva, " +
    "possibilitando a seus usuários uma maior autonomia e segurança ao explorar espaços públicos. Através de feedbacks auditivos, esta tecnologia fornece informações " +
    "detalhadas sobre rotas, obstáculos, pontos de interesse e outras informações cruciais do entorno, facilitando a mobilidade urbana e promovendo uma " +
    "inclusão mais efetiva na sociedade.",
];

// Estilização do TabPanel para garantir consistência visual
const FullWidthTabPanel = styled('div')(({ theme }) => ({
    width: '100%',
    maxWidth: `${theme.breakpoints.values.lg}px`,
    margin: '2px auto 0 auto', // Define o espaçamento superior do TabPanel
    padding: theme.spacing(5), // Define o espaçamento interno do TabPanel
    borderRadius: '20px', // Define os cantos arredondados do TabPanel
    backgroundColor: theme.palette.primary.main, // Define a cor de fundo do TabPanel
    color: theme.palette.primary.contrastText, // Define a cor do texto dentro do TabPanel
    textAlign: 'center', // Alinha o texto ao centro
}));

const Produtos = () => {
    const [value, setValue] = useState(0); // Estado para controlar a aba ativa
    const theme = useTheme(); // Acessa o tema atual para uso nas estilizações
    const isDarkMode = theme.palette.mode === 'dark'; // Determina se o tema atual é escuro

    // Função para alterar a aba ativa
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    // Função para determinar qual logo mostrar com base na aba selecionada e no tema
    const getLogo = (index: number) => {
        return value === index ? (isDarkMode ? [Logo1Light, Logo2Light, Logo3Light][index] : [Logo1Dark, Logo2Dark, Logo3Dark][index]) : (isDarkMode ? [Logo1Dark, Logo2Dark, Logo3Dark][index] : [Logo1Light, Logo2Light, Logo3Light][index]);
    };

    return (
        <Container maxWidth="lg" sx={{ paddingBottom: theme.spacing(5) }}>
            <Typography variant="h4" component="h2" sx={{ my: 4, textAlign: 'center' }}>
                Conheça os nossos produtos
            </Typography>
            <Paper elevation={0} sx={{ bgcolor: 'transparent' }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="fullWidth"
                    textColor="primary"
                    sx={{
                        '.MuiTabs-indicator': { display: 'none' }, // Remove o indicador de aba ativa
                        // Define a altura das tabs de forma global
                        '.MuiTab-root': { minHeight: '80px' }, // Ajusta a altura das tabs
                    }}
                >
                    {[0, 1, 2].map((index) => (
                        <Tab
                            key={index}
                            // Exibe a logo correspondente com a altura específica ajustada
                            icon={<Box component="img" src={getLogo(index)} sx={{ height: logoHeights[index], maxWidth: index === 2 ? '60%' : '100%', maxHeight: '100%'}} />}
                            sx={{
                                bgcolor: value === index ? theme.palette.primary.main : 'transparent', // Altera o fundo da aba ativa
                                borderRadius: 5, // Define cantos arredondados para as tabs
                            }}
                        />
                    ))}
                </Tabs>
                {[0, 1, 2].map((index) => (
                    <FullWidthTabPanel key={index} role="tabpanel" hidden={value !== index}>
                        <Typography>{descriptions[index]}</Typography>
                    </FullWidthTabPanel>
                ))}
            </Paper>
        </Container>
    );
};

export default Produtos;
