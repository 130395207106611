// Contatos.tsx
import React from 'react';
import { Container, Typography, Box, Grid, IconButton, Tooltip } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { styled } from '@mui/system';

const ContactBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(3),
    borderRadius: theme.shape.borderRadius,
    transition: 'transform 0.3s ease-in-out',
    '&:hover': {
        transform: 'scale(1.05)',
    },
}));

const Contatos = () => {
    return (
        <Container maxWidth="lg" sx={{ my: 4 }}>
            <Typography variant="h4" gutterBottom sx={{ textAlign: 'center', mb: 4 }}>
                Entre em Contato
            </Typography>
            <Grid container spacing={4} justifyContent="center">
                <Grid item xs={12} sm={4}>
                    <ContactBox>
                        <IconButton color="primary" href="mailto:contato@aiacontext.com">
                            <EmailIcon sx={{ fontSize: 40 }} />
                        </IconButton>
                        <Typography variant="h6">contato@aiacontext.com</Typography>
                    </ContactBox>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <ContactBox>
                        <IconButton color="primary" href="tel:+5598982449903">
                            <PhoneIcon sx={{ fontSize: 40 }} />
                        </IconButton>
                        <Typography variant="h6">+55 98 98244-9903</Typography>
                    </ContactBox>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <ContactBox>
                        <Tooltip title="Visite nosso LinkedIn" placement="top">
                            <IconButton color="primary" href="https://www.linkedin.com/company/aia-context" target={"_blank"}>
                                <LinkedInIcon sx={{ fontSize: 40 }} />
                            </IconButton>
                        </Tooltip>
                        <Typography variant="h6">LinkedIn</Typography>
                    </ContactBox>
                </Grid>
            </Grid>
        </Container>
    );
};

export default Contatos;
